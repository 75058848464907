import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class GoldSaver extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ออมทอง' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />


                <div className="gold-saver-area" data-black-overlay="5">
                    <div className="section-1 ptb--120 pb--150" data-black-overlay="5">
                        <div className="container text-center">
                            <div className="section-title">
                                <div className="row">
                                    <h3 className="title-color">ออมทอง </h3>
                                    <h3 className="title">คืออะไร?</h3>
                                </div>
                                <h4 className="description">การซื้อทองคำแบบใหม่ ที่ทำให้คุณไม่จำเป็นที่จะต้องใช้เงินก้อนใหญ่มาซื้อทองคำ</h4>
                                <p className="description">โดยรูปแบบจะเป็นการทยอยสะสมทองคำ ด้วยการซื้อสะสมไว้ทีละเล็กทีละน้อย</p>
                                <p className="description">เพื่อรับผลตอบแทนจากราคาทองในอนาคตหรือออมเพื่อซื้อทองคำแท่ง มาเก็บไว้กับตัวคุณเองก็ได้</p>
                                <div className="row">
                                    <p className="description">การออมทองสามารถเริ่มต้นออมได้ด้วยเงินเพียงหลัก</p>
                                    <p className="description-color">100 บาท</p>
                                    <p className="description">ต่อวันเท่านั้น</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="section-2 bg_color--1 ptb--80">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/goldsaver/goldsaver-1.webp" alt="ทองรูปพรรณ" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="section-title mt--20">
                                        <h3 className="title-color">ประโยชน์ของการออมทอง</h3>
                                        <p className="description">✔ ได้เป็นเจ้าของ ทองคำแท่ง ได้โดยไม่ต้องใช้เงินก้อนใหญ่ในการซื้อ<br />
                                            ✔ สะดวก ทำการซื้อ-ขาย หรือ ออมทอง<br />
                                            ✔ ได้สินทรัพย์ที่จับต้องได้ มีมูลค่าอยู่ตลอด<br />
                                            ✔ ช่วยสร้างวินัยในการออม</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" bg_color--1">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <a href="https://lin.ee/htkgF3M" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/line-contact-2.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร" /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-3 attacment-fixed  bg_color--1 ptb--80">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-lg-6 col-md-12">
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : เปิดบัญชีอย่างไร?</h4>
                                        <p className="description">A : ขั้นต่ำ 50-100 บาท เลือกออม รายเดือน/รายสัปดาห์ ส่งงวดละเท่าไหร่ก็ได้</p>
                                    </div>
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : กำหนดระยะเวลา?</h4>
                                        <p className="description">A : ไม่ได้กำหนดระยะเวลาการออม</p>
                                    </div>
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : ช่องทางการออม?</h4>
                                        <p className="description">A : โอนเงินและส่งหลักฐานผ่านไลน์แอดหรือ Facebook หรือเดินทางมาที่หน้าร้าน</p>
                                    </div>
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : หลักฐานการออม?</h4>
                                        <p className="description">A : บันทึกยอดให้ทุกครั้งทั้งออนไลน์และมีสมุดออมให้ลูกค้า</p>
                                    </div>
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : เลือกสินค้าได้?</h4>
                                        <p className="description">A : เลือกรับทองได้วันที่ออมครบ ราคาทองเป็นราคาวันสุดท้ายที่มารับทอง</p>
                                    </div>
                                    <div className="section-title mt--20">
                                        <h4 className="title-color">Q : ออมทองใช้อะไรบ้าง?</h4>
                                        <p className="description">A : รูปถ่ายบัตรประชาชน เบอร์โทรที่ติดต่อได้ ที่อยู่ที่สามารถส่งของได้</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail mt--20">
                                        <img className="w-100" src="/assets/images/goldsaver/goldsaver-2.webp" alt="ทองรูปพรรณ" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}

                < Footer />

            </React.Fragment >
        )
    }
}
export default GoldSaver;
