import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='เกี่ยวกับเรา' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />


                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us  bg_color--1 bg_image--7 " data-black-overlay="6">
                    <div className="bg-blur">
                        <div className="container bg-blur">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="inner">
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h4 className="">จุดกำเนิดนาจันทร์สว่างฟาร์มสเตย์ยโสธร</h4>
                                                <p>เริ่มจากช่างทองที่มีความเชี่ยวชาญและชำนาญการในเรื่องทอง เปิดร้านภายใต้ชื่อ “นาจันทร์สว่างฟาร์มสเตย์ยโสธร” โดยได้เปิดสาขาแรกอยู่ที่ จังหวัดยโสธร เยื้องธนาคารกรุงศรียโสธร เลขที่ 266 ถนนอุทัยรามฤทธิ์  ตำบลในเมือง จังหวัดยโสธร ต่อมากิจการร้านทองได้เติบโตขึ้นเรื่อย ๆ จึงได้ขยายสาขาเพิ่มขึ้นเป็นสาขาที่ 2 อยู่ตรงข้ามโรงพยาบาลยโสธร เลขที่ 106/5 หมู่ 2 ตำบลเขื่องคำ อำเภอเมือง จังหวัดยโสธร</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}



                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 mb--50">
                                    <img src="/assets/images/contact/branch-1.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร สาขา1 " />
                                    <h4 className="mt--20">นาจันทร์สว่างฟาร์มสเตย์ยโสธร สาขา 1 เยื้องธนาคารกรุงศรียโสธร</h4>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb--50">
                                    <img src="/assets/images/contact/branch-2.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร สาขา2 " />

                                    <h4 className="mt--20">นาจันทร์สว่างฟาร์มสเตย์ยโสธร สาขา 2 ตรงข้ามโรงพยาบาลยโสธร</h4>
                                </div>
                            </div>


                            <div className="row row--20 align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-1.webp" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="header-left d-flex align-items-center">
                                            <div className="logo">
                                                <img src="/assets/images/logo/logo.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร" />
                                            </div>
                                        </div>
                                        <div className="section-title mt--20">
                                            {/* <h2 className="title">นาจันทร์สว่างฟาร์มสเตย์ยโสธร</h2> */}
                                            <p className="description">เปิดมาแล้วเป็นเวลาเกือบ 70 ปี จากรุ่นหนึ่งผู้บุกเบิก สืบทอดมาถึงรุ่นสองผู้จุดประกายความสำเร็จ และได้ดำเนินธุรกิจต่อเนื่องมาจนถึงรุ่นที่สามในปัจจุบันนี้ พวกเราเริ่มต้นจากการขายทองรูปพรรณ โดยเน้นไปที่เนื้อทองและคุณภาพเป็นหลัก ผ่านยุคสมัยของการซื้อเพื่อความสวยงามและสะสม ทองคำเริ่มมีการพัฒนามาเป็น การซื้อขายทองคำแท่งเพื่อการลงทุน นาจันทร์สว่างฟาร์มสเตย์ยโสธรจึงได้พัฒนาการบริการเพื่อให้ลูกค้าได้รับความสะดวกมากขึ้น โดยมีราคาอ้างอิงทองคำแท่งของสมาคมค้าทองคำแห่งประเทศไทยได้ตลอดเวลา</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="des-area">
                    <div className="bg-blur ptb--120 ptb_mobile--40">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--20 align-items-center">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="about-inner inner">
                                            <div className="section-title mt--20 mb--40 mt_mobile--10">
                                                <h2 className="title">ยกระดับการขายทอง</h2>
                                                {/* <p className="description">นาจันทร์สว่างฟาร์มสเตย์ยโสธรพร้อมเข้าสู่ยุคดิจิทัล จึงยกระดับการขายทองออนไลน์อย่างเต็มตัว โดยใช้มาตรฐานเดียวกับสาขาหน้าร้าน<br />
                                                    ✔ สินค้าคุณภาพมาตรฐานทอง 96.5% (ทองเยาวราช) รับรองจากสมาคมค้าทองคำ<br />
                                                    ✔ รับประกันราคาคืนตามราคาทอง (หากต้องการขายคืนกรุณาติดต่อหน้าร้าน “นาจันทร์สว่างฟาร์มสเตย์ยโสธร" ทั้ง 2 สาขา เปิดให้บริการในจังหวัดยโสธร)<br />
                                                    ✔ สินค้าทุกชิ้นมีใบรับประกัน</p> */}
                                                    <p className="description">✔ สินค้าคุณภาพมาตรฐานทอง 96.5% (ทองเยาวราช) รับรองจากสมาคมค้าทองคำ</p>
                                                    <p className="description">✔ รับประกันราคาคืนตามราคาทอง (หากต้องการขายคืนกรุณาติดต่อหน้าร้าน “นาจันทร์สว่างฟาร์มสเตย์ยโสธร" ทั้ง 2 สาขา เปิดให้บริการในจังหวัดยโสธร)</p>
                                                    <p className="description">✔ สินค้าทุกชิ้นมีใบรับประกัน</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="thumbnail mt_mobile--20">
                                            <img className="w-100" src="/assets/images/about/about-4.webp" alt="ทองรูปพรรณ" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="bottom-area">
                    <div className="bg-blur ptb--120 ptb_mobile--40">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--20 align-items-center">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-5.webp" alt="ทองรูปพรรณ" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="about-inner inner">
                                            <div className="section-title mt--20  mt_mobile--10">
                                                <h2 className="title">เรื่องทอง ไว้ใจเรา</h2>
                                                <p className="description">นาจันทร์สว่างฟาร์มสเตย์ยโสธรจำหน่ายทองรูปพรรณ-ทองแท่ง เพรชแท้ เงินแท้ นากแท้ มีใบรับประกัน เจาะหูฟรี มีบริการซ่อม เลี่ยมกรอบพระ งานสั่งทำ ทองคำแท้จากเยาวราชกรุงเทพ คุณภาพตามมาตรฐานสคบ</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About