import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ProductMenu from "./product/ProductMenu";


class Product extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ทองรูปพรรณ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                <div className="product-area">
                    <div className="product-top attacment-fixed bg_image--6" data-black-overlay="5">
                        <div className="bg-blur ptb--80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title mb--30 text-center">
                                            <h3 className="title ">ทองรูปพรรณ</h3>
                                            <p className="description mt--30">ทองรูปพรรณ เป็นทองคำที่นำมาจากกรรมวิธีการหลอม หล่อ และรีดให้เป็นชิ้นงานตามต้องการ ต้องอาศัยการออกแบบ และฝีมือช่างที่มีความประณีตและชำนาญ จึงออกมาเป็นเครื่องประดับที่มีความสวยงาม
                                                นาจันทร์สว่างฟาร์มสเตย์ยโสธร ดำเนินธุรกิจซื้อขายทองคำแท่งและซื้อขายทองคำรูปพรรณ  รวมถึงเครื่องประดับ พร้อมดูแลช่วงเวลาที่แสนพิเศษไปพร้อมกับการสานต่อความคุ้มค่าในเรื่องของการซื้อทองคำ มั่นใจได้ว่าทุกงานฝีมือจะได้รับการออกแบบและจัดทำอย่างประณีตและถูกต้องตามมาตรฐาน
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="product-des shortcut-menu-area">
                        <div className="ptb--40 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title mb--30 text-center">
                                            <h4 className="title">คุณลักษณะทองรูปพรรณ</h4>
                                            <p className="description mt--30">เคยมีลูกค้าหลายท่านสงสัยว่า เนื้อของทองรูปพรรณนั้นจะเปลี่ยนแปลงไปตามช่วงเวลาที่ซื้อหรือไม่? คำตอบ คือ ไม่ว่าคุณจะซื้อทองรูปพรรณวันนี้หรือวันไหน “ช่วงเวลา” ก็จะส่งผลต่อราคาการซื้อขายทองคำเพียงอย่างเดียว เว้นเสียแต่ คุณซื้อทองรูปพรรณที่มีความบริสุทธิ์ที่แตกต่างกัน ซึ่งทองรูปพรรณจะมีความบริสุทธิ์ด้วยกัน 2 แบบ คือ ความบริสุทธิ์ 99.99% ที่เป็นทองคำมาตรฐานโลก และ ความบริสุทธิ์ 96.5% ที่เป็นมาตรฐานทองไทย
                                                ซึ่งโดยส่วนใหญ่แล้ว <b>การซื้อขายทองรูปพรรณในไทยจะเป็นความบริสุทธิ์ 96.5% และมีน้ำหนักอยู่ที่ 1 บาท หรือ 15.16 กรัม</b></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="content">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-12">
                                                    <div className="row">
                                                        <img src="/assets/images/about/about-7.webp" alt="ทองคำแห่งประเทศไทย" />
                                                        <p><b>คุณภาพทองคำ 96.5% </b><br></br>
                                                            มาตรฐานจากสมาคมค้าทองคำแห่งประเทศไทย</p>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-12">
                                                    <div className="row">
                                                        <img src="/assets/images/about/about-6.webp" alt="ทองคำแห่งประเทศไทย" />
                                                        <p><b>
                                                            น้ำหนัก 1 บาท = 15.16 กรัม</b><br></br>
                                                            น้ำหนักทองคํารูปพรรณมาตรฐาน</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="section-3 ptb--40 bg_color--2 " data-black-overlay="5">
                            <div className="container">

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="section-title-2 mb--30 text-center">
                                                <h4 className="title">การผลิตทองรูปพรรณ</h4>
                                                <p className="description mt--30">การผลิตทองรูปพรรณต้องอาศัยทั้งอุปกรณ์ เครื่องมือ และกรรมวิธี รวมทั้งฝีมือของช่างทองในการออกแบบ และแกะลวดลายต่างๆ โดยเริ่มจากการคัดเลือกเนื้อทองซึ่งมีคุณภาพต่างกัน เพื่อนำไปใช้เป็นส่วนประดับของทองรูปพรรณ</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="section-title-3 mb--30 ">
                                                <h4 className="title text-center">ข้อดีของทองรูปพรรณ</h4>
                                                {/* <p className="description mt--30">
                                                    ✔	มีราคาเริ่มต้นที่จับต้องได้<br/>
                                                    ✔	สามารถซื้อเก็บไว้เพื่อออมเริ่มต้นการลงทุนได้<br/>
                                                    ✔	ใช้สวมใส่เป็นเครื่องประดับแสดงฐานะได้<br></br>
                                                    ✔	ใช้สวมใส่เป็นเครื่องประดับเพื่อความสวยงามได้<br></br>
                                                    ✔	แลกเปลี่ยนเป็นเงินสดได้อย่างคล่องตัว
                                                </p> */}
                                                <p className="description mt--30">✔ มีราคาเริ่มต้นที่จับต้องได้</p>
                                                <p className="description">✔ สามารถซื้อเก็บไว้เพื่อออมเริ่มต้นการลงทุนได้</p>
                                                <p className="description">✔ ใช้สวมใส่เป็นเครื่องประดับแสดงฐานะได้</p>
                                                <p className="description">✔ ใช้สวมใส่เป็นเครื่องประดับเพื่อความสวยงามได้</p>
                                                <p className="description">✔ แลกเปลี่ยนเป็นเงินสดได้อย่างคล่องตัว</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="ptb--40 bg_color-- ">
                            <div className="container">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <img className="w-100" src="/assets/images/product/productsize-01.webp" alt="วิธีวัดขนาดแหวน" />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt_mobile--20">
                                        <img className="w-100" src="/assets/images/product/productsize-02.webp" alt="วิธีวัดสร้อยแขน" />
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>





                    <div className="bg_color--1">
                        <div className=" ptb--40 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title mb--30 text-center">
                                            <h4 className="title">ทองรูปพรรณของนาจันทร์สว่างฟาร์มสเตย์ยโสธร</h4>
                                            <p className="description mt--30">ปัจจุบันมีทองรูปพรรณที่ร้านค้าทองได้ผลิตออกมาให้ลูกค้าได้เลือกซื้อนั้นมีรูปแบบที่หลายหลาย ทุกๆชิ้นงานมีความสวยงามทันสมัย สวยงามใส่ได้ทุกโอกาส ทุกวัน ทุกยุคทุกสมัย นาจันทร์สว่างฟาร์มสเตย์ยโสธร จำหน่ายให้ลูกค้าทุกท่านได้ตามความต้องการ โดยลูกค้าสามารถคลิกที่รูปภาพเพื่อดูรายละเอียดของการสั่งทำทองรูปพรรณแบบต่าง ๆ ได้ หรือหากต้องการสั่งทำก็สามารถติดต่อสอบถามกับทางร้านได้ทุกเวลาทำการ</p>
                                            <div className="shortcut-menu-area bg_color--1">
                                                <div className="portfolio-sacousel-inner mb--55">
                                                    <ProductMenu />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>


                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment >
        )
    }
}
export default Product;
