import React, { Component } from "react";
import { Link } from 'react-router-dom';

const ShortcutMenuList1_image = <img src="/assets/images/home/gold-1.webp" alt="ทองคำ"  />;
const ShortcutMenuList2_image = <img src="/assets/images/home/gold-2.webp" alt="ทองคำ"  />;
const ShortcutMenuList3_image = <img src="/assets/images/home/gold-3.webp" alt="ทองคำ"  />;


const ShortcutMenuList = [
    {
        image: ShortcutMenuList1_image,
        link: '/goldbar',
        title: 'Host Concept'
    },
    {
        image: ShortcutMenuList2_image,
        link: '/product',
        title: 'E-Saan Thai Food'
    },
    {
        image: ShortcutMenuList3_image,
        link: '/goldsaver',
        title: 'be a farmmer'
    },
]

class ShortcutMenu extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="shortcut-menu-wrapper">
                    <div className="container">
                        <div className="row">
                            {ShortcutMenuList.map((value, index) => (
                                <div className="block col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" key={index}>
                                    <div className="im_shortcut-menu">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to={value.link}>
                                                    {value.image}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <h4 className="title"><Link to="">{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to={value.link} aria-label="นาจันทร์สว่างฟาร์มสเตย์ยโสธร"></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ShortcutMenu;